
.home{
    color: $white;
    max-width: 900px;
    margin: 0 auto;

    .coming{
        text-align: center;
        font-family: $font-logo;
        font-size: $xxxlarge;
        color: $orange;

        h3{
            font-size: $large;
        }
    }

    .left-side{
        margin-left: $mar-m;
        font-family: $font-sans;
        font-size: $norm + .3rem;
        color: $blue;
        width: 50%;
        @media (max-width: $tablet){
            width: 40%;
            font-size: $norm;
        }

        @media (max-width: $mobal){
            display: flex;
            flex-direction: column;
            width: 90%;
            
        }
        
    }

    .top-window{
        margin-top: 30px;
        display: flex;
        @media (max-width: $mobal){
            flex-direction: column;
        }
    }

    .right-side{
        width: 50%;
        @media (max-width: $tablet){
            width: 60%;
        }
        @media (max-width: $mobal){
            width: 100%;
            
        }

        
    }

    
}

.loading{
    font-size: 7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 300px;
    color: $orange;
}

.temp-text{
    font-size: $norm;
    line-height: 18px;
}