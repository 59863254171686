.admin-login{
    margin-top: $mar-m;

    .adlog-container{
        width: 45%;
        min-height: 200px;
        border: 2px solid $purple;
        border-radius: 12px;
        margin: $mar-s auto;

        .err-msg{
            text-align: center;
            font-size: $small + .3rem;
            color: $red;
            margin-top: 10px;
        }
        
        @media (max-width: $tablet){
            width: 90%;
        }
    }

    .adlog-form{
        padding: $pad-m;
        

        .label{
            margin-bottom: 2px;
        }

        .input{
            color: $white;
            width: 100%;
            background-color: transparent;
            border: 2px solid $blue;
            height: 30px;
            border-radius: 14px;
            margin-bottom: $mar-s;
        }

        button{
            width: 100%;
            background-color: $purple;
            height: 30px;
            border-radius: 14px;
            border: none;
            cursor: pointer;
        }

        .red{
            color: $red;
        }
    }
}

.loading2{
    font-size: 7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    color: $orange;
}

.loading3{
    font-size: 7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 60px;
    color: $orange;
   
}