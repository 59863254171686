.q-options{
   display: flex;
   justify-content: space-between;
  
    button{
        width: 60px;
        border-radius: 12px;
        border: none;
        height: 20px;
        margin-right: 5px;
        cursor: pointer;

    }

    .read-btn{
        background-color: $green;
    }

    .mark-btn-0{
        background-color: $purple;
    }

    .mark-btn-1{
        background-color: $orange;
    }

    .delete-btn{
        background-color: $red;
    }

}

.full-q-window{
    margin-top: 5px;
    font-size: $small + .2rem;
    line-height: 16px;
    transition: 0.5s;
    overflow-y: scroll;

    h2{
        color: $blue;
        margin-bottom: $mar-s;
        margin-top: $mar-s;
        font-size: $small + .3rem;
    }

    &::-webkit-scrollbar {
        height: 12px;
        width: 12px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: $purple;
        border-radius: 5px;
        //-webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
    }

    &::-webkit-scrollbar-corner {
        background: #000;
    }

}
