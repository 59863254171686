.admin-panel{
    display: flex;
    @media (max-width: $mobal){
        flex-direction: column-reverse;
    }
   .user-wndw{
       width: 30%;  
       @media (max-width: $mobal){
        width: 100%;
        margin-top: $mar-s;
    }
   }
}