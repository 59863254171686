.join{
    max-width: 900px;
    margin: $mar-l auto;
    padding: 0 $pad-s;
    
    header{
        h2{
            color: $purple;
            font-family: $font-logo;
            font-size: $xxlarge;
            text-align: center;
        }
    }

    p{
        font-family: $font-sans;
        font-size: $norm;
        color: $lt-blue;
    
    }

    .patreon{
        margin: 15px auto;
        width: 200px;
        text-align: center;
        p{
            color: #ff424d; 
        }
       
       
    }

    .pat-logo{
        font-size: $xlarge;
        color: #ff424d;
        margin-bottom: 8px;
    }

    .message{
        margin-top: $mar-m;
        h5{
            font-family: $font-logo;
            font-size: $xxlarge - .3rem;
            color: $orange;
            text-align: center;

        }

        p{
            margin-bottom: $mar-s;
            line-height: 17px;
        }
    }
}