.whats-new{
    margin-top: 20px;
    
    .header{
        border-bottom: 2px solid $lt-blue;
        width: 200px;
        margin: 0 auto;
    }
    h2{
        text-align: center;
        font-family: $font-logo;
        font-size: $xxlarge;
        color: $blue;
        
    }

    .txt-ppl{
        color: $purple;
    }

    .post-list{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    }
}