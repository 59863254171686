.nav-bar{
    color: white;
    background: linear-gradient(115deg , $blue 0%, $purple 80%);
    width: 100%;
    height: 40px;
    padding-top: 2px;
    margin-bottom: $mar-s;

    .inner-box{
        width: 100%;
        height: 36px;
        background-color: $dk-blue;
        display: flex;
        align-items: center;
        justify-content: space-around;
        font-family: $font-logo;
        font-size: $large;

        .link{
            width: 65px;
            text-align: center;

        }
        
        .inactiveLink{
            color: $purple;
            &:hover{
                color: $orange;
            }
        }

        .activeLink{
            color: $lt-blue;
            &:hover{
                color: $blue;
            }
        }

    }
}