.user-window{
    margin: 0px $mar-s;
    border: 2px solid $purple;
    border-radius: 14px;
    padding: 4px;
    min-height: 190px;
    
    .message{
        height: 16px;
        text-align: center;    
    }

    button{
        width: 100%;
        border: none;
        border-radius: 12px;
        height: 25px;
        margin-top: 10px;
        cursor: pointer;
    }

    .logout-btn{
        background-color: $blue;

    }

    .reset-pass{
        background-color: $orange;
    }

    .reset-code{
        background-color: $purple;
    }

    .hello{
        text-align: center;
        font-size: $norm;
        margin-bottom: 10px;
        margin-top: 5px;
    }

    .name{
        text-align: center;
        font-size: $large;
    }
}