.schedule{
    width: 100%;
    padding: 0px $pad-l;

    h3{
        font-size: $large;
        font-family: $font-logo;
        text-align: center;
        color: $purple;
        border-bottom: 2px solid $blue;
        margin-bottom: 6px;
    }
    
    .episode-card-main{
        font-size: $norm - .1rem;
        margin-bottom: $mar-s;
        &:hover{
            .col-1{
                color: $red;
            }
        }
        .info-display{
            display: flex;

            .col-1{
                width: 50%;
                max-height: 16px;
                overflow-y: hidden;
               
            }

            .col-2{
                font-size: $small;
                width: 25%;
                padding-left: 5px;
                text-align: center;

                .out-now{
                    color: $green;
                    
                }
                //border: 1px solid white;
            }

            .col-3{
                width: 25%;
                font-size: $small;
                padding-left: 5px;
                text-align: center;

                .sub{
                    display: flex;
                    justify-content: space-evenly;
                    font-size: $norm;
                }

            }
        }
    }

    .orange{
        color: $orange;
    }

    .purple{
        color: $purple;
    }

    .blue{
        color: $lt-blue;
    }
}