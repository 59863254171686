.quick-question{
    color: $white;

    .colored-text{
        // @media (max-width: $mobal){
        //     margin-top: $mar-m;
        // }
        color: $blue;
        font-family: $font-sans;
        font-size: $norm;
        text-align: center;
    
        p{
            margin-bottom: 10px;
            // @media (max-width: $mobal){
            //     text-align: center;
            // }

            &:nth-child(1){
                font-size: $large;
                margin-bottom: 10px;
                // @media (max-width: $tablet){
                //     font-size: $norm + .5rem;
                // }
            }

            &:nth-child(2){
                color: $purple;   
            } 

            &:nth-child(4){
                color: $purple;  
            } 

        
        }
    }

    .request{
        font-family: $font-sans;
        font-size: $norm;
        margin: $mar-s;
        padding-top: $pad-m;
        border-top: 2px solid $blue;
        color: $blue;
        
        p{
            &:nth-child(1){
                font-size: $norm + .4rem;
                margin-bottom: 5px;
                color: $orange;
            }
        }
    
    }

   
}