.add-episode{
    margin: $mar-s $mar-s;
    border: 2px solid $purple;
    border-radius: 14px;
    padding: 4px;
    min-height: 190px;
    padding: $pad-m;

    h3{
        text-align: center;
        font-size: $norm;
    }

    p{
        text-align: center;
        margin-top: $mar-s;
        color: $green;
    }

    .red{
        color: $red;
    }

    .flex-box{
        margin-top: $mar-s;
        display: flex;
        justify-content: space-between;

        @media (max-width: 900px ){
            flex-direction: column;
            .spacer{
                margin-top: $mar-s;
            }
        }

        @media (max-width: $mobal ){
            flex-direction:row;
            .spacer{
                margin-top: 0px;
            }
        }


    }

    .field{
        width: 100%;
        border: 2px solid $blue;
        background: none;
        border-radius: 10px;
        margin-top: 4px;
        color: $white;
        
    }

    .select{
        margin-top: 4px;
        border: 2px solid $blue;
        background: none;
        color: $white;
    }

    .date{
        background: $lt-blue;
        color: $black;
    }

    .textarea{
        border-bottom-right-radius: 0px;
        min-height: 100px;
    }

    .mar{
        margin-top: $mar-s;
    }

    .btn{
        margin-top: $mar-s;
        width: 100%;
        border-radius: 10px;
        background: $orange;
        border: none;
        padding: 2px;
    }

}

.modal-adjust{
    margin-top: 40px;
}