.main-header{
    padding: $pad-s;
    display:flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: wrap;
    color: $white;
    //margin-bottom: $mar-s;
    @media (max-width: $mobal){
       flex-direction: column;
    }

    @media (max-width: 753px){
        justify-content: center;
    }

    .logo-name{
        display: flex;
    }

    h1{
        font-size: $xxxlarge + .8rem;
        font-family: $font-logo;
        margin-left: $mar-s;
        color: $blue;

        .purp{
            color: $purple;
        }
    }

    p{
        margin-left: $mar-l;
        font-family: $font-sans;
        font-size: $norm;
        
    }

    .btn-box{
        width: 150px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: $mobal){
            margin-top: 5px;
        }

        button{
            background: linear-gradient(115deg , $blue 0%, $purple 80%);
            border: none;
            height: 25px;
            border-radius: 15px;
            color: $white;
            cursor: pointer;
            width: 150px;
            font-weight: bold;
        }
    }
    
    
    // .tag-adjust{
    //     //margin-left: 150px;
    //     @media (max-width: $tablet){
    //         margin-left: $mar-l;
    //     }
    // }

}

.email-q{
    margin-right: 20px;
    color: $lt-blue;
    a{
        font-size: $norm;
        color: $orange;
    }
}