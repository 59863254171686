$dk-blue: #010e43;
$dk-blue-2: #01040C;
$blue: #1160F2;
$lt-blue:#1DA1F2; 
$purple: #9C0AF3;
$red: #DC0081;
$orange: #FF7A00;
$yellow: #FFFC00;
$green: #3EE600;

$white: #e6e6e6;
$black: #000000;
$gray: #8c8c8c;

// font sizes
$xxxsmall: 0.6rem;
$xxsmall: 0.8rem;
$xsmall: 1rem;
$small: 1.2rem;
$norm: 1.5rem;
$medium: 2rem;
$large: 2.5rem;
$xlarge: 3rem;
$xxlarge: 4rem;
$xxxlarge: 5rem;

//padding sizes
$pad-s: 5px;
$pad-m: 10px;
$pad-l: 15px;

//margin sizes
$mar-s: 12px;
$mar-m: 20px;
$mar-l: 30px;

//break points
$mobal: 610px;
$tablet: 680px;
$pc: 1024px;

$logo-font: 'Road Rage', cursive;
$font-logo: 'Bangers', sans-serif;
$font-sans: 'Exo 2', sans-serif;

body{
    background: linear-gradient(to bottom, $dk-blue 20%, $dk-blue-2 100%);
    background-repeat: no-repeat;
    background-attachment: fixed;
    
}

.facebook{
    color: #4267B2;
}

.twitter{
    color: #1DA1F2;
}

.patreon{
    color: #ff424d;
}