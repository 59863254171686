.episodes{
    color: $white;
    max-width: 900px;
    margin: $mar-l auto;
    padding: 0px 5px;

    .headline{
        font-family: $font-sans;
        font-size: $norm;
        color: $lt-blue;
        text-align: center;
    }
    
   
    
    h2{
        font-family: $font-logo;
        font-size: $xxlarge;
        text-align: center;
        color: $purple;
    }

    .ep-title{
        font-family: $font-logo;
        text-align: center;
        font-size: $xlarge;
        color: $purple;
        width: 200px;
        margin: 0px auto;
        margin-top: 30px;
        border-bottom: 2px solid $blue;
        
    }

    .direction{
        margin-top: 4px;
        text-align: center;
        margin-bottom: 15px;
    }

    .player-window{
        width: 100%;
        display: flex;
        margin: $mar-m 0px;
        justify-content: center;
    }

    .catalogue{
        display: flex;
        flex-wrap: wrap;
        @media (max-width: $mobal){
            flex-direction: column;
            
        }

        .ends{
            width: 33%;
            @media (max-width: $mobal){
                width: 95%;
            }
        }

        .middle{
            width: 34%;
            @media (max-width: $mobal){
                width: 95%;
            }
        }
        
        article{
            display: flex;
            flex-direction: column;
            align-items: center;
           

            header{
                height: 40px
            }

            h5{
                font-size: $large;
                font-family: $font-logo;
                color: $purple;
            }

            h6{
                text-align: end;
                font-family: $font-sans;
                color: $orange;
            }

            .blu{
                color: $blue;
            }

            .hours{
                color: $red;
                //margin-bottom: 10px;
            }

            .orange{
                color: $orange;
            }
            .thoughts{
                color: $green;
                //margin-bottom: 10px;
            }

            .lt-blu{
                color: $lt-blue;
            }

            .green{
                color: $green;
                
            }

            .ep-list{
                width: 100%;
                padding: 0px 20px;

            }

            .ep-card{
                display: flex;
                justify-content: space-between;
                width: 100%;
                font-family: $font-sans;
                font-size: $small + .2rem;
                margin-bottom: $mar-s;
                cursor: pointer;
                &:hover{
                    color: $lt-blue;
                }

                .title{
                    width: 70%;
                }

                .date{
                    color: $orange;
                }

                .release{
                    width: 30%;
                    text-align: center;
                }
                
            }
        }
    }
}