.about{
    max-width: 900px;
    margin: $mar-l auto;
    padding: 0px 5px;
    
   
    
    h2{
        font-family: $font-logo;
        font-size: $xxlarge;
        text-align: center;
        color: $purple;
    }

    h3{
        font-family: $font-logo;
        font-size: $xlarge;
        color: $purple;
        margin-top: $mar-m;
    }

    p{
        font-family: $font-sans;
        font-size: $norm;
        //color: lighten($color: $blue, $amount: 5%);
        color: $lt-blue;
        line-height: 16px;
        margin-bottom: $mar-s;
    }

    .email{
        color: $orange;
        font-size: $norm + 0.5rem;
        margin-right: 10px;
        
    }

    .profiles{
        width: 75%;
        margin: 0 auto;
    }

    .icon{
        font-size: $norm + 0.5rem;
        margin: 0px 10px;
    }
}