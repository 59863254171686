.post-card{
    width: 704px;
    min-height: 140px;
    background: linear-gradient(115deg , $blue 0%, $purple 80%);
    border-radius: 10px;
    padding: 2px;
    margin: 15px 0;

    @media (max-width: $tablet){
        max-width: 604px;
    }
    @media (max-width: $mobal){
       max-width: 320px;
        
    }

    .container{
        background: $dk-blue;
        max-width: 700px;
        min-height: 136px;
        border-radius: 8px;
        padding: $pad-s;

        @media (max-width: $tablet){
            max-width: 600px;
        }
        @media (max-width: $mobal){
            max-width: 316px;
        }
    }
}

.video-post{
    display: flex;
    @media (max-width: $mobal){
       flex-direction: column;
       align-items: center;
    }

    .info-box{
        padding: $pad-m;
        width: 65%;
        @media (max-width: $mobal){
            width: 95%;
        }

        header{
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            width: 100%;
            border-bottom: 2px solid $blue;
            h2{
                color: $purple;
                @media (max-width: $tablet){
                    font-size: $large + 0.5rem;
                }
            }

            p{
                margin-bottom: 6px;
                font-size: $norm - .3rem;
                
                @media (max-width: $tablet){
                    font-size: $norm - .4rem;
                }
            }
        }

        .description{
            margin-top: 20px;
            font-family: $font-sans;
            font-size: $norm;
            line-height: 17px;
            color: $lt-blue;
        }

        .links{
            margin-top: $mar-m;

            p{
                margin-bottom: 4px;
                font-size: $small;
                color: $lt-blue
            }

            a{
                font-size: $norm;
                color: $red;
            }
        }
        
    }
}

.audio-post{
    display: flex;
    flex-direction: column;
    align-items: center;
    header{
        h2{
            color:$purple
        }
        p{
            text-align: center;
            font-size: $norm - .3rem;
        }
    }

    .description{
        width: 90%;
        margin-top: 20px;
        margin-bottom: 20px;
        font-family: $font-sans;
        font-size: $norm;
        line-height: 17px;
        color: $lt-blue;

    }

    .links{
        margin-top: $mar-s;
        margin-bottom: $mar-s;

        p{
            margin-bottom: 4px;
            font-size: $small;
            color: $lt-blue
        }

        a{
            font-size: $norm;
            color: $red;
        }
    }

    img{
        max-width: 250px;
        padding-bottom: 10px;
    }
}


