.change-pass-ad{
    width: 100%;
    min-height: 400px;
    

    .form-container{
       
        width: 75%;
        margin: 5px auto;
        
        .err-msg{
            color: $red;
            text-align: center;
            font-size: $norm;
            margin-bottom: 10px;
        }

        h2{
            text-align: center;
            font-size: $large;
            color: $purple;
            margin-bottom: $mar-l;
        }

        .form-box{

            .red{
                color: $red;
            }

            .btm-spacer{
                margin-bottom: 40px;
            }

            .input{
                width: 100%;
                margin-top: 5px;
                margin-bottom: 10px;
                height: 25px;
                border-radius: 14px;
                background-color: transparent;
                color: $white;
            }

            .current{
                border: 2px solid $blue;
            }

            .new{
                border: 2px solid $purple
            }

            button{
                width: 100%;
                height: 25px;
                border: none;
                background-color: $orange;
                border-radius: 14px;
                cursor: pointer;

            }
        }

       
    } 
    .submitted-window{
        h2{
            text-align: center;
            font-size: $norm;
            color: $green;
        }

        p{
            margin-top: 100px;
            text-align: center;
            font-size: $norm;
        }

        button{
            width: 100%;
            height: 25px;
            margin-top: 100px;
            background-color: $orange;
            border: none;
            border-radius: 14px;
            cursor: pointer;
        }
    }
}