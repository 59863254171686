.ep-nav{
    color: $lt-blue;
    
    header{
        display: flex;
        justify-content: space-between;
        font-size: $norm;
        margin-bottom: 5px;
        .next{
            margin-right: 10px;
        }

        .prev{
            margin-left: 10px;
        }
    }

    .header-next{
        flex-direction: row-reverse;
    }
}