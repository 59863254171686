.video{
    //border: 1px solid white;
    width: 250px;
    padding: 5px;

    .container{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .controlls{
            width: 100%;
            display: flex;

            .play-btn{
                font-size: $large;
                color: $purple;
                background: none;
                border: none;
            }
            
            .slider{
                width: 125px;
                accent-color: $lt-blue;
                @media (max-width: $mobal){
                    width: 100px;
                }
               
            }

            

            .speed{
                background: none;
                border: none;
                color: $lt-blue;
                width: 40px;
            }

            .mute{
                font-size: $norm;
                background: none;
                border: none;
                color: $purple;
            }
            
        }
    }
    video{
        max-width: 200px;
    }
}