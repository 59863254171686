.questions-window{
    // width: 70%;
    // min-height: 500px;
    // border: 2px solid $blue;
    // border-radius: 12px;
    padding: $pad-l;
    font-size: $small;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    // @media (max-width: $mobal){
    //     width: 100%;
    // }
    .btm-dashed{
        border-bottom: 1px dashed $blue;
        margin-bottom: 10px;
    }

    .row{
        width: 100%;
        height: 25px;
        //min-height: 25px;
        display: flex;
        padding-bottom: 4px;
        
        .purp{
            color: $purple;
        }
        
        @media (max-width: 785px){
            flex-wrap: wrap;
            height: inherit;
            //min-height: 25px;
        }
    }

    .col-1{
        min-width: 120px;
        overflow-y: hidden;
    }

    .col-2{
        min-width: 50%;
        max-width: 50%;
        overflow-y: hidden;
    
    }

    .col-3{
        width: 30%;
    
        @media (max-width: 785px){
            margin-top: 5px;
            width: 50%;
        }
    }

    

}