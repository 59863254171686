.modal{
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.4);

    .modal-content{
        background-color: $dk-blue;
        margin: 15% auto;
        padding: 20px;
        border: 2px solid $orange;
        border-radius: 14px;
        width: 70%;
        @media (max-width: $tablet){
            width: 90%;
            
        }
    }

    .close{
        color: $red;
        float: right;
        font-size: $norm + .3rem;
        font-weight: bold;
        
        &:hover,:focus{
            color: darken($red, 20%);
            text-decoration: none;
            cursor: pointer;
        }
    }

}