.main-footer{
    margin-top: $mar-l;
    border-top: 2px solid $purple;
    min-height: 100px;

    .logo-container{
        width: 60px;
        margin: 10px auto;

       
    }

    .name{
        text-align: center;
        font-family: $font-logo;
        font-size: $large + .5rem;
        color: $purple;
        
    }

    .blu{
        color: $blue;
    }

    .copyright{
        color: $white;
        text-align: center;
       
        
    }

    address{
        color: $white;
        text-align: center;
        margin-top: 5px;
        margin-bottom: 20px;
    }

    .sandbox{
        margin: 10px;
    }
}