.data-display{
    width: 70%;
    min-height: 500px;
    border: 2px solid $blue;
    border-radius: 12px;
    padding-top: $pad-l;
    font-size: $small;

    @media (max-width: $mobal){
        width: 100%;
    }

    .display-menu{
        display: flex;
        justify-content: space-evenly;
        border-bottom: 2px solid $purple;
        padding-bottom: $pad-l;

        button{
            border: none;
            background: $blue;
            border-radius: 10px;
            padding: 2px;
            width: 80px;
            cursor: pointer;

            &:hover{
                background-color: $purple;
            }
        }
    }
}