.logo{
    font-family: $logo-font;
    color: $purple;
    font-size: $xxxlarge;
    //main color
    background: linear-gradient(115deg , $blue 0%, $purple 80%);
    
    //alt colors
    //background: linear-gradient(115deg , $red 0%, $orange 80%);
    //background: linear-gradient(115deg , $green 0%, $yellow 80%);
    //background: linear-gradient(115deg , $purple 0%, $red 80%);
    //background: linear-gradient(115deg , $lt-blue 0%, $green 80%);

    //border-radius: 12px;
    border-radius: 20%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

   .inner-box{
        background: $dk-blue;
        border-radius: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .a{
        color: $blue;
        
        //alt colors
        //color: $red;
        //color: $green;
        //color: $purple;
        //color: $lt-blue;
        
    }
    
    .h{
       color: $purple;
       
       //alt color
       //color: $orange;
       //color: $yellow;
       //color: $red;
       //color: $green;
    }


    
}