.audio{
    width: 400px;
    height: 55px;
    padding: 5px;
    border: 2px solid $blue;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: $mobal){
        width: 300px;
    }

    .controlls{
        width: 100%;
        display: flex;
        align-items: center;

        .play-btn{
            font-size: $xlarge;
            background: none;
            border: none;
            color: $purple;
        }

        .slider{
            width: 240px;
            accent-color: $lt-blue;
            @media (max-width: $mobal){
                width: 200px;
            }
        }

        .speed{
            color: $lt-blue;
            font-size: $norm;
            background: none;
            margin: 5px;
            border-color: $purple;
        }

        .mute{
            color: $purple;
            background: none;
            border: none;
            font-size: $norm + .5rem;
        }
    }
}