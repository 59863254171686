.episode-card{
    border-bottom: 2px dashed $blue;
    .row{
        width: 100%;
        height: 25px;
        display: flex;
        align-items: center;
        padding-top: 4px;
        
        
        @media (max-width: 500px){
            flex-wrap: wrap;
            height: inherit;
            //min-height: 25px;
        }
    }

    .col-1{
        min-width: 70px;
        overflow-y: hidden;
    }

    .col-2{
        min-width: 30%;
        max-width: 30%;
        overflow-y: hidden;
    
    }

    .col-3{
        width: 40%;
        
    
        // @media (max-width: 785px){
        //     margin-top: 5px;
        //     width: 50%;
        // }
    }

    .col-4{
        width: 30%;
        display: flex;
        justify-content: flex-end;
        color: $white;

        div{
            margin-left: 5px;
            cursor: pointer;
        }

        @media (max-width: $mobal){
            justify-content: flex-start;
        }
    }

    .full-window{
        margin-top: 5px;
        font-size: $small + .2rem;
        line-height: 16px;
        transition: 0.5s;
        overflow-y: hidden;

        div{
            display: flex;
            align-items: center;

            h2{
                width: 50px;
            }

            p{
               
                margin-left: 10px;
            }
        }
    
        h2{
            color: $blue;
            margin-bottom: $mar-s;
            margin-top: $mar-s;
            font-size: $small + .3rem;
        }
    
        &::-webkit-scrollbar {
            height: 12px;
            width: 12px;
            background: transparent;
        }
    
        &::-webkit-scrollbar-thumb {
            background: $purple;
            border-radius: 5px;
            //-webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
        }
    
        &::-webkit-scrollbar-corner {
            background: #000;
        }
    }

    
}
.white{
    color: $white;
}

.blue{
    color: $blue;
}

.purple{
    color: $purple;
}