.wwd-window{
    margin: $mar-s $mar-s;
    border: 2px solid $blue;
    border-radius: 14px;
    padding: 4px;
    min-height: 190px;
    padding: $pad-m;

    h4{
        text-align: center;
        font-size: $small + 0.3rem;
    }

    .wwd-card{
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
    }

    .wwd-inner-window{
        min-height: 140px;
    }

    .form-window{
        border-top: 2px dashed $blue;
        padding-top: 8px;

        p{
            text-align: center;
        }
    }

    .field{
        width: 100%;
        border: 2px solid $purple;
        background: none;
        border-radius: 10px;
        margin-top: 4px;
        color: $white;
        margin-bottom: 6px;
    }

    .btn{
        width: 100%;
        background-color: $orange;
        border-radius: 10px;
        color: $black;
        border: none;
        height: 20px;
        margin-top: 6px;
        cursor: pointer;
    }

    .red{
        color: $red;
    }
}

.loading4{
    font-size: 7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 140px;
    //margin-top: 20px;
    color: $orange;
}