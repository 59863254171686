.ep{
    color: $white;
    max-width: 900px;
    margin: $mar-l auto;
    padding: 0px 5px;
    .askin{
        display: flex;
        justify-content: center;
        p{
            text-align: end;
            font-family: $font-sans;
            font-size: $norm;
            color: $orange;
        }

        .blu{
            color: $blue;
        }
    }
    h2{
        font-family: $font-logo;
        font-size: $xxlarge;
        color: $purple;
        text-align: center;
    }

    h3{
        font-family: $font-logo;
        font-size: $xxlarge - .5rem;
        text-align: center;
        color: $lt-blue;
        margin-top: $mar-m;
    }

    .hours{
        color: $red;
    }

    .org{
        color: $orange;
    }

    .thoughts{
        color: $green;
    }

    .lt-blu{
        color: $lt-blue;
    }

    .episode-page{
        margin-top: $mar-s;
        margin-bottom: $mar-l + 20px;

        .top{
            display: flex;
            
            .img-box{
                width: 60%;
                img{
                    width: 100%;
                }
                @media (max-width: $mobal){
                    margin-bottom: $mar-s;
                }
            }
            
            @media (max-width: $mobal){
                flex-direction: column;
                align-items: center;
                margin-right: $mar-s;
            }

            .des-box{
                width: 100%;
                min-height: 350px;
                margin-left: $mar-m;
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                @media (max-width: $mobal){
                    min-height: 250px;
                }
                
                .des{
                    margin-bottom: $mar-s;
                    font-size: $norm + .4rem;
                }
            }

            .info-box{
                width: 100%;
                display: flex;
                justify-content: space-between;
                font-family: $font-sans;
                font-size: $norm;
                
            }
        }

        .player-box{
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: $mar-s;

        }

        .coming-soon{
            width: 400px;
            height: 55px;
            padding: 5px;
            border: 2px solid $blue;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: $font-logo;
            font-size: $xxlarge;
            color: $orange;
        }

        .patreon-btn{
            width: 400px;
            height: 55px;
            padding: 5px;
            border: 2px solid #ff424d;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: $font-sans;
            font-size: $xlarge;
            color: #ff424d;
            cursor: pointer;

            &:hover{
                border-color: $lt-blue;

                p{
                    color: $lt-blue;
                }
            }

            p{
                font-size: $norm;
                margin-left: 20px;
                
            }
        }
    }

    .subscribe{
        font-size: $norm + .5rem;
    }

    .ep-nav-window{
        display: flex;
        justify-content: space-between;
    }
}