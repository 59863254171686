.rss-player{
    width: 600px;
    min-height: 350px;
    border: 2px solid $blue;
    border-radius: 5px;
    //padding: 6px;
    @media (max-width: 600px){
        width: 100%;
    }
   

    .top-row{
        display: flex;
        padding: 6px;
        @media (max-width: $mobal){
            flex-direction: column;
            align-items: center;
        }
    }

    img{
        width: 200px;
    }

    h3{
        font-family: $font-logo;
        font-size: $large;
        text-align: center;
        color: $purple;
    }

    .description{
        padding: $pad-m;
        font-family: $font-sans;
        font-size: $norm - .1;

    }

    .player{
        margin: $mar-s 0px; 
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .episodes-list{
        width: 100%;
        height: 60px;
        border-top: 2px solid $blue;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            height: 12px;
            width: 12px;
            background: transparent;
        }
    
        &::-webkit-scrollbar-thumb {
            background: $purple;
            border-radius: 5px;
            //-webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
        }
    
        &::-webkit-scrollbar-corner {
            background: #000;
        }

        .entry{
            width: 100%;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;

            img{
                width: 60px;
                padding-left: 5px;
            }

            h4{
                font-family: $font-logo;
                font-size: $norm +.5;
                color: $lt-blue;
                
            }

            p{
                font-size: $small;
            }

            .last{
                padding: 0px 5px;

            }

        }
    }

    .load{
        height: 293px;
        color: $lt-blue;
        font-size: $xxxlarge + 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}