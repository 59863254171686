.q-form{
    width: 90%;
    padding: $pad-m;
    font-size: $small;

    label{
        color: $orange
    }

    .text-field{
        width: 100%;
        height: 30px;
        margin-top: 3px;
        margin-bottom: 15px;
        background-color: transparent;
        border: 2px solid $blue;
        border-radius: 14px;
        color: $white;
        padding-left: 6px;

    }

    .text-area{
        width: 100%;
        margin-top: 3px;
        min-height: 160px;
        border: 2px solid $blue;
        border-radius: 14px 14px 0px 14px;  
        background-color: transparent;
        color: $white;
        padding: 6px;

    }

    button{
        width: 100%;
        height: 30px;
        margin-top: 10px;
        border-radius: 14px;
        background: $purple;
        border: none;
        font-size: $norm;
        font-family: $font-sans;
        font-weight: bold;
        cursor: pointer;

    }

    .red{
        color: $red;
    }
}

.form-div{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 350px;
    
}

.q-count{
   justify-self: end;
}