.tier-display{
    color: white;
    

    h3{
        font-family: $font-logo;
        color: $red;
        font-size: $xlarge + .4rem;
        text-align: center;
    }

    .container{
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;

        .tier-card{
            border: 2px solid $blue;
            border-radius: 8px;
            width: 200px;
            min-height:  300px;
            margin: 8px;
            padding: 5px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &:nth-child(even){
                border-color: $purple;
            }

            h4{
                font-family: $font-logo;
                font-size: $large;
                color: $orange;
                text-align: center;
            }

            .price{
                text-align: center;
                color: $green;
                font-size: $xxxlarge;
                margin-bottom: 12px;

                .month{
                    font-size: $small;
                    color: $lt-blue;
                }
            }

            .perks{
                padding: 10px;

                p{
                    margin-bottom: 6px;
                    color: $lt-blue;
                }
            }

            .btn-container{
                padding: 5px;
                width: 90%;
                margin: 0 auto;
                button{
                    padding: 6px;
                    width: 100%;
                    border: none;
                    border-radius: 15px;
                    background-color: #ff424d;
                    
                    span{
                        margin-left: 10px;
                        font-weight: bold;
                    }

                }
            }

        }
    }

    .b-e-descrip{
        margin-top: 10px;

        p{
            margin-bottom: 8px;
        }
    }

    h5{
        font-size: $large;
        font-family: $font-logo;
        color: $purple;
    }
}
