.quick-descrip{
    display: flex;
    @media (max-width: $tablet){
        flex-direction: column;
    }
    

    .descrip-container{
        margin-left: $mar-m;
        max-width: 80%;
        @media (max-width: $tablet){
            max-width: 100%;
            margin-left: 0px;
        }
        p{
            margin-bottom: $mar-s;
        }
        .purp{
            color: $purple;
        }
    }

    .logo-box{

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        .icon{
            text-align: center;
            //margin-top: $mar-s;
            font-size: 5rem;
            @media (max-width: $mobal){
                margin-bottom: 5px;
            }
        }

        .icon-2{
            text-align: center;
            //margin-top: $mar-s;
            font-size: 4rem;
            @media (max-width: $mobal){
                margin-bottom: 5px;
            }
        }


        @media (max-width: $tablet){
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-evenly;
            margin-bottom: $mar-s;
        }
    }

    .btn-container{
        width: 100%;
        min-height: 120px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;

        
    }

    .q-btn{
        background: linear-gradient(115deg , $blue 0%, $purple 80%);
        border: none;
        border-radius: 15px;
        color: $white;
        height: 30px;
        width: 250px;
        font-weight: bold;
        cursor: pointer;
    }

    .s-btn{
        background: linear-gradient(115deg , $red 0%, $orange 80%);
        border: none;
        border-radius: 15px;
        color: $dk-blue;
        height: 30px;
        width: 250px;
        font-weight: bold;
        cursor: pointer;
    }
}

.socal-q{
    font-size: $norm;
    text-align: center;

    h3{
        font-size: $norm + .5rem;
    }

    p{
        margin-top: 20px;
    }
    a{
        display: block;
        color: $orange;
        text-decoration: underline;
        margin-bottom: 5px;
        margin-top: 10px;
    }
}
